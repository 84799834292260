// src/Login.js
import React from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import { getAuthUrl } from '../../services/api';
import LogoDark from "../../assets/images/logo_mb.png";
import LogoLight from "../../assets/images/logo_m.png";

function Login() {
    document.title = "Accesos a Almacenes | MENORCA";
    const handleLogin = async () => {
        try {
            const response = await getAuthUrl();
            window.location.href = response.data.auth_url;
        } catch (error) {
            console.error('Error during login', error);
        }
    };

    return (
       
            <div className="auth-page-content mt-lg-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center m-5 text-white-50">
                                            <img src={LogoLight} className="" alt="logo dark" height="60" />
                                        </div>
                                        <div className="text-center mt-2">
                                            <h2 className="fs-20 text-primary mb-3">CONTROL DE ACCESOS MENORCA</h2>
                                            <h5 className="text-primary">Bienvenido Administrador!</h5>
                                            <p className="text-muted">Para iniciar sesion, continua con el siguiente botón</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <div className="mt-4">
                                                <button className="btn w-100 btn-success" onClick={handleLogin}>Ingresar con Correo</button>                                     
                                            </div>
                                        </div>
                                    </CardBody> 
                            </Card>
                        </Col> 
                    </Row>
                </Container>
            </div>
       
    );
}

export default Login;