import React from 'react';
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

function Principal() {
  return (
        <div >
            <section className="pb-0 " id="hero">
                <div className="bg-overlay bg-overlay-pattern"></div>
                <Container>
                    {/* Aquí se añade la barra de navegación superior */}
                    <Row className="justify-content-end pt-3">
                        <Col xs="auto">
                            <a href="https://qaalmacenes.menorca.services/login" className="btn btn-outline-primary">Login</a>
                        </Col>
                    </Row>
                    {/* Fin de la barra de navegación */}
                    
                    <Row className="justify-content-center h-100">
                        <div className="text-center mt-lg-1 pt-5 pb-5">
                            <h2 className='fw-semibold'>BIENVENIDO AL CONTROL DE ACCESO <span className='text-primary'> A ALMACENES MENORCA</span></h2>
                            <p className='fw-normal mt-5 fs-15 text-muted'>Haz click en una de las siguientes opciones para continuar, gracias.</p>
                        </div>
                        <Col lg={6} sm={6}>
                            <Link className="navbar-brand" to="/FormularioM">
                                <Card className="Card bg-danger">
                                    <CardBody>
                                        <div className='m-5'>
                                            <h2 className='text-center text-white'>COLABORADOR </h2>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={6} sm={6}>
                            <Link className="navbar-brand" to="/FormularioE">
                                <Card className="Card bg-warning" >
                                    <CardBody>
                                        <div className='m-5'>
                                            <h2 className='text-center text-white'>PROVEEDOR </h2>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
  )
}

export default Principal;