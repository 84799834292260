// src/firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBbSSih2FisqD6iBBxwaBdleiK2o5y_oWQ",
    authDomain: "test-6b3de.firebaseapp.com",
    projectId: "test-6b3de",
    storageBucket: "test-6b3de.appspot.com",
    messagingSenderId: "872892773659",
    appId: "1:872892773659:web:62f2c033f4833f84010fbe",
    measurementId: "G-EVQ33W31GK"
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);