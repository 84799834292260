import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getTokens } from './services/api';

function Callback() {
    const location = useLocation();

    useEffect(() => {
        const getCode = async () => {
            const urlParams = new URLSearchParams(location.search);
            const code = urlParams.get('code');
            try {
                const response = await getTokens(code);
                localStorage.setItem('tokens', JSON.stringify(response.data));
                window.location.href = '/AdminLayout'; // Redirige al usuario a la página de inicio
            } catch (error) {
                console.error('Error getting tokens', error);
            }
        };

        getCode();
    }, [location]);

    return null;
}

export default Callback;