import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import LogoLight from "../../assets/images/logo-light.png";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="custom-footer bg-dark py-5 position-relative">
        <Container>
         

          <Row className="text-center text-sm-start align-items-center mt-5">
            <Col className="col-sm-6">
              <div>
                <p className="copy-rights mb-0">
                  {new Date().getFullYear()} ©
                  Menorca - Control de Accesos
                </p>
              </div>
            </Col>
           
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
