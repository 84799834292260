import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { messaging } from './firebase';
import axios from 'axios';
import { getToken, onMessage } from 'firebase/messaging';

import FormularioM from './pages/FormularioM/FormularioM';
import FormularioE from './pages/FormularioE/FormularioE';
import Login from '../src/pages/Authentication/Login';
import Callback from './Callback';
import Home from './Home';
import AdminLayout from './pages/Admin/AdminLayout';
import Principal from './pages/index/Principal';

import PrivateRoute from './PrivateRoute'; 

//import Scss
import './assets/scss/themes.scss';

function App() {
  useEffect(() => {
    getToken(messaging)
      .then((token) => {
        console.log(token);
      })
      .catch((error) => {
        console.error("Failed to get token", error);
      });
  }, []);

  return (
    <Router>
      <div>
        <Routes> {/* Envuelve tus rutas dentro de <Routes> */}
          <Route path="/" element={<Principal />} />
          <Route path="/login" element={<Login />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/formularioM" element={<FormularioM />} /> {/* Utiliza el prop "element" para pasar el componente */}
          <Route path="/formularioE" element={<FormularioE />} />
          <Route element={<PrivateRoute /> } >
            <Route path="/AdminLayout" element={<AdminLayout /> } />
            <Route path="/home" element={<Home />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;


