import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import axios from 'axios';
import { enviarDatosFormulario } from '../../services/api_enviar';

const FormularioE = () => {
    const [formulario, setFormulario] = useState({
        ruc: '',
        nombre_empresa: '',
        dni: '',
        nombre_usuario: '',
        sede: '', // Sin valor predeterminado
        motivo: '',
        observaciones: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'ruc') {
            const rucRegex = /^[0-9]{0,11}$/;
            if (rucRegex.test(value)) {
                setFormulario({ ...formulario, [name]: value });
            }
        } else if (name === 'nombre_empresa') {
            if (value.length <= 100) {
                setFormulario({ ...formulario, [name]: value });
            }
        } else if (name === 'dni') {
            const dniRegex = /^[0-9]{0,8}$/;
            if (dniRegex.test(value)) {
                setFormulario({ ...formulario, [name]: value });
            }
        } else if (name === 'nombre_usuario') {
            const nombreRegex = /^[A-Za-z\s]{0,80}$/;
            if (nombreRegex.test(value)) {
                setFormulario({ ...formulario, [name]: value });
            }
        } else if (name === 'sede') {
            // Establecemos el valor directamente
            setFormulario({ ...formulario, [name]: value });
        } else if (name === 'motivo') {
            if (value.length <= 100) {
                setFormulario({ ...formulario, [name]: value });
            }
        } else if (name === 'observaciones') {
            if (value.length <= 150) {
                setFormulario({ ...formulario, [name]: value });
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Enviando formulario...', formulario);
        try {
            const response = await enviarDatosFormulario(formulario);
            console.log('Respuesta del servidor:', response);

            setFormulario({
                ruc: '',
                nombre_empresa: '',
                dni: '',
                nombre_usuario: '',
                sede: '', // Reiniciar a vacío después del envío
                motivo: '',
                observaciones: ''
            });
        } catch (error) {
            console.error('Error al enviar formulario:', error);
        }
    };

    return (
        <div className="">
            <section className="pb-0 " id="hero">
                <div className="bg-overlay bg-overlay-pattern"></div>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} sm={10}>
                            <div className="text-center mt-lg-1 pt-5 pb-5">
                                <h2 className='fw-semibold'>CONTROL DE ACCESO <span className='text-info'>EXTERNO</span> A ALMACENES <span className='text-primary'>MENORCA</span></h2>
                            </div>
                            <Card className="Card">
                                <CardBody>
                                    <Row className="justify-content-center">
                                        <Col lg={10} sm={10}>
                                            <div className='border-bottom'>
                                                <div className='m-3 mb-5'>
                                                    <span className='text-muted'>PASO 1</span>
                                                    <h5 className='fw-bold '>DETALLES DE ACCESOS</h5>
                                                </div>
                                            </div>
                                            <div className='m-3 mt-4'>
                                                <p className='mb-4'>Para ingresar al almacén, complete los siguientes campos con sus datos correctos:</p>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="live-preview">
                                                        <Row className="mb-3">
                                                            <Col xxl={3} md={6}><div><label className="form-label" htmlFor="ruc">RUC:</label></div></Col>
                                                            <Col xxl={9} md={6}><div><input className="form-control" type="text" id="ruc" name="ruc" value={formulario.ruc} onChange={handleChange} /></div></Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col xxl={3} md={6}><div><label className="form-label" htmlFor="nombre_empresa">Nombre de Empresa:</label></div></Col>
                                                            <Col xxl={9} md={6}><div><input className="form-control" type="text" id="nombre_empresa" name="nombre_empresa" value={formulario.nombre_empresa} onChange={handleChange} /></div></Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col xxl={3} md={6}><div><label className="form-label" htmlFor="dni">DNI:</label></div></Col>
                                                            <Col xxl={9} md={6}><div><input className="form-control" type="text" id="dni" name="dni" value={formulario.dni} onChange={handleChange} /></div></Col>
                                                        </Row> 
                                                        <Row className="mb-3">
                                                            <Col xxl={3} md={6}><div><label className="form-label" htmlFor="nombre_usuario">Nombre:</label></div></Col>
                                                            <Col xxl={9} md={6}><div><input className="form-control" type="text" id="nombre_usuario" name="nombre_usuario" value={formulario.nombre_usuario} onChange={handleChange} /></div></Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col xxl={3} md={6}><div><label className="form-label" htmlFor="sede">Sede de Ingreso:</label></div></Col>
                                                            <Col xxl={9} md={6}>
                                                                <div>
                                                                    <select className="form-control" id="sede" name="sede" value={formulario.sede} onChange={handleChange}>
                                                                        <option value="" disabled>Seleccione una sede</option>
                                                                        <option value="1">1 - Almacen Central</option>
                                                                        <option value="2">2 - Posada del sol Ica</option>
                                                                    </select>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col xxl={3} md={6}><div><label className="form-label" htmlFor="motivo">Motivo de Ingreso:</label></div></Col>
                                                            <Col xxl={9} md={6}><div><input className="form-control" type="text" id="motivo" name="motivo" value={formulario.motivo} onChange={handleChange} /></div></Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col xxl={3} md={6}><div><label className="form-label" htmlFor="observaciones">Mensaje:</label></div></Col>
                                                            <Col xxl={9} md={6}><div><textarea className="form-control" id="observaciones" name="observaciones" value={formulario.observaciones} onChange={handleChange} /></div></Col>
                                                        </Row>
                                                        <div className="text-end">
                                                            <button type="submit" className="btn btn-primary">Enviar</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default FormularioE;
