// src/api.js
import axios from 'axios';

const API_URL = 'https://apialmacenes.menorca.services'; // Reemplaza esto con la URL de tu API de Django

export function getAuthUrl() {
    return axios.get(`${API_URL}/api/user/login/`);
}

export function getTokens(code) {
    return axios.get(`${API_URL}/api/user/callback/?code=${code}`);
}