// src/Home.js
import React from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { getToken } from 'firebase/messaging';
import { messaging } from './firebase';

function Home() {
  useEffect(() => {
    const sendToken = async () => {
      // Obtén el token FCM
      const fcmToken = await getToken(messaging);

      // Obtén el ID del usuario del almacenamiento local
      const tokens = JSON.parse(localStorage.getItem('tokens'));
      const token = tokens.access;
      const payload = token.split('.')[1];
      const decoded = atob(payload);
      const user_id = JSON.parse(decoded).user_id;

      // Enviar el token FCM y el ID del usuario al backend
      axios.post('https://apialmacenes.menorca.services/api/register/token/', {
        user_id: user_id,
        fcmToken: fcmToken
      }, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error(error);
        });
    };

    sendToken();
  }, []);

  return (
    <div>
      <h1>Home Page</h1>
      <p>Welcome to the home page!</p>
    </div>
  );
}

export default Home;