import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getToken } from 'firebase/messaging';
import { messaging } from '../../firebase';
import { Card,Button, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip,UncontrolledPopover,PopoverHeader,PopoverBody,Modal,ModalHeader,ModalBody,Alert } from "reactstrap";
import PropTypes from "prop-types";
import { Link, Navigate } from 'react-router-dom';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import withRouter from '../../Components/Common/withRouter';
import classnames from "classnames";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Format_hour from '../../helpers/Format_hour';


function AdminLayout() {


  document.title = "Pagina de Solicitudes | Menorca Almacenes";
  const [activeTab, setActiveTab] = useState('1');
  const [solicitudes, setSolicitudes] = useState([]);
  const [motivoRechazoModal, setMotivoRechazoModal] = useState(false); // Definir estado para el modal de motivo de rechazo
  const [motivoRechazoState, setMotivoRechazoState] = useState(''); // Definir estado para el motivo de rechazo
  const [solicitudIdRechazo, setSolicitudIdRechazo] = useState(null); // Definir estado para el ID de la solicitud que será rechazada
  const [observacionesFinalizacionModal, setObservacionesFinalizacionModal] = useState(false);
  const [observacionesFinalizacionState, setObservacionesFinalizacionState] = useState('');
  const [solicitudIdFinalizacion, setSolicitudIdFinalizacion] = useState(null);



  /*tab*/
   // Justify Tabs
  // Pills Justified Tabs
  const [justifyPillsTab, setjustifyPillsTab] = useState("1");
  const justifyPillsToggle = (tab) => {
      if (justifyPillsTab !== tab) {
          setjustifyPillsTab(tab);
      }
  };

  // Header Justify Tabs
  const [headerJustifyTab, setheaderJustifyTab] = useState("1");
  const headerJustifyToggle = (tab) => {
      if (headerJustifyTab !== tab) {
          setheaderJustifyTab(tab);
        }
  };



  const [modal_center, setmodal_center] = useState(false);
  function tog_center() {
      setmodal_center(!modal_center);
  }
  

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    
    const sendTokenAndGetRecords = async () => {
      try {
        // Obtén el token FCM
        const fcmToken = await getToken(messaging);
  
        // Obtén el ID del usuario del almacenamiento local
        const tokens = JSON.parse(localStorage.getItem('tokens'));
        const tokena = tokens.access;
        const payload = tokena.split('.')[1];
        const decoded = atob(payload);
        const user_id = JSON.parse(decoded).user_id;

        console.log("Tokens from localStorage:", tokens);
        console.log("Soy el token " + tokena);
        console.log("Authorization Header:", `Bearer ${tokena}`);

        console.log(`soy el FCMToKEN ${fcmToken}`);
  
        // Enviar el token FCM y el ID del usuario al backend
        await axios.post('https://apialmacenes.menorca.services/api/register/token/', {
          user_id: user_id,
          fcmToken: fcmToken
        }, {
          headers: {
            'Authorization': `Bearer ${tokena}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          }
        });
  
        // Una vez enviado el token, obtén los registros
        const response = await axios.get('https://apialmacenes.menorca.services/api/register/getallregisters/', {
          headers: {
            Authorization: `Bearer ${tokena}`
          }
        });
        
        setSolicitudes(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    sendTokenAndGetRecords();
  }, []);

  
  const handleAprobar = async (solicitudId) => {
    try {
      const tokens = JSON.parse(localStorage.getItem('tokens'));
      const tokena = tokens.access;
    
        // Obtener la fecha y hora actual
        const fechaAprobacion = new Date().toISOString();
    
      // Enviar solicitud para actualizar el estado de la solicitud y la fecha de aprobación
      await axios.put(`https://apialmacenes.menorca.services/api/register/putregister/${solicitudId}/`, {
        estado: 'Estado.APROBADO',
        fecha_aprobacion: fechaAprobacion // Agregar la fecha de aprobación
      }, {
        headers: {
          Authorization: `Bearer ${tokena}`
        }
      });
    
      // Actualizar el estado localmente
      const updatedSolicitudes = solicitudes.map(solicitud => {
        if (solicitud.id === solicitudId) {
          solicitud.estado = 'Estado.APROBADO';
          solicitud.fecha_aprobacion = fechaAprobacion; // Actualizar la fecha de aprobación localmente
        }
        return solicitud;
      });
      setSolicitudes(updatedSolicitudes);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleFinalizar = async (solicitudId) => {
    try {
      // Abre el modal para que el usuario ingrese las observaciones de finalización
      setObservacionesFinalizacionModal(true);
      setSolicitudIdFinalizacion(solicitudId);
    } catch (error) {
      console.error('Error:', error);
    }
  };
 // Esta función se llamará cuando el usuario haga clic en "Aceptar" en el modal de observaciones de finalización
  const handleAceptarFinalizacion = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem('tokens'));
      const tokena = tokens.access;

      // Obtener la fecha y hora actual
      const fechaSalida = new Date().toISOString();

      // Obtener las observaciones de finalización ingresadas por el usuario
      const observacionesFinalizacion = observacionesFinalizacionState;

      // Enviar solicitud para actualizar el estado de la solicitud y las observaciones de finalización
      await axios.put(`https://apialmacenes.menorca.services/api/register/putregister/${solicitudIdFinalizacion}/`, {
        estado: 'Estado.FINALIZADO',
        fecha_salida: fechaSalida,
        observaciones_finalizacion: observacionesFinalizacion // Agregar las observaciones de finalización
      }, {
        headers: {
          Authorization: `Bearer ${tokena}`
        }
      });

      // Actualizar el estado localmente
      const updatedSolicitudes = solicitudes.map(solicitud => {
        if (solicitud.id === solicitudIdFinalizacion) {
          solicitud.estado = 'Estado.FINALIZADO';
          solicitud.fecha_salida = fechaSalida;
          solicitud.observaciones_finalizacion = observacionesFinalizacion; // Agregar las observaciones de finalización localmente
        }
        return solicitud;
      });
      setSolicitudes(updatedSolicitudes);

      // Cierra el modal de observaciones de finalización
      setObservacionesFinalizacionModal(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handleRechazar = async (solicitudId) => {
    try {
        // Abre el modal para que el usuario ingrese el motivo de rechazo
        setMotivoRechazoModal(true);
        setSolicitudIdRechazo(solicitudId);
    } catch (error) {
        console.error('Error:', error);
    }
  };

  // Esta función se llamará cuando el usuario haga clic en "Aceptar" en el modal de motivo de rechazo
  const handleAceptarRechazo = async () => {
      try {
          const tokens = JSON.parse(localStorage.getItem('tokens'));
          const tokena = tokens.access;

          // Obtener la fecha y hora actual
          const fechaSalida = new Date().toISOString();
          // Obtener el motivo de rechazo del estado local
          const motivoRechazo = motivoRechazoState;

          // Enviar solicitud para actualizar el estado de la solicitud
          await axios.put(`https://apialmacenes.menorca.services/api/register/putregister/${solicitudIdRechazo}/`, {
              estado: 'Estado.RECHAZADO',
              fecha_salida: fechaSalida,
              motivo_rechazo: motivoRechazo // Agregar el motivo de rechazo
          }, {
              headers: {
                  Authorization: `Bearer ${tokena}`
              }
          });

          // Actualizar el estado localmente
          const updatedSolicitudes = solicitudes.map(solicitud => {
              if (solicitud.id === solicitudIdRechazo) {
                  solicitud.estado = 'Estado.RECHAZADO';
                  solicitud.fecha_salida = fechaSalida;
                  solicitud.motivo_rechazo = motivoRechazo; // Agregar el motivo de rechazo localmente
              }
              return solicitud;
          });
          setSolicitudes(updatedSolicitudes);

          // Cierra el modal de motivo de rechazo
          setMotivoRechazoModal(false);
      } catch (error) {
          console.error('Error:', error);
      }
  };

  const filterSolicitudesByEstado = (estado) => {
    return solicitudes.filter(solicitud => solicitud.estado === estado);
  };

  return (
    <React.Fragment>
    <Navbar />
    <section className="section job-hero-section py-5 bg-light pb-0" id="hero">
      <Container>
        <Row className="justify-content-between align-items-center">
          <Col xs={12}>
              <CardHeader className="align-items-center pt-5 border-0 mb-4 d-flex">
                <div className="flex-shrink-0">
                  <Nav pills className="nav-justified mb-3 nav nav-pills">
                    <NavItem>
                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: justifyPillsTab === "1", })} onClick={() => { justifyPillsToggle("1"); }} >
                        solicitudes
                      </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: justifyPillsTab === "2", })} onClick={() => { justifyPillsToggle("2"); }} >
                        Aprobadas
                      </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: justifyPillsTab === "3", })} onClick={() => { justifyPillsToggle("3"); }} >
                        Finalizadas
                      </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: justifyPillsTab === "4", })} onClick={() => { justifyPillsToggle("4"); }} >
                        Rechazadas
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </CardHeader>
              <div className="card-body p-0">
                <TabContent activeTab={justifyPillsTab}  className="text-muted"> 
                  <TabPane tabId="1">
                    <div className='mb-4'>
                      <h4>Solicitudes para Ingreso</h4>
                    </div>
                   <Row>
                      {filterSolicitudesByEstado('Estado.POR_APROBAR').map(solicitud => (
                          <Col xl={4} ms={12}>
                            <Card className="card-height-100">
                              <CardHeader>
                                <div className="d-flex align-items-center mb-2">
                                    <div className="flex-grow-1">
                                      <span>{new Date(solicitud.fecha_registro).toLocaleTimeString()} </span>
									  <span>{new Date(solicitud.fecha_registro).toLocaleDateString()}</span>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <span>Por Aprobar</span>
                                    </div>
                                  </div>
                              </CardHeader>
                              <CardBody>
                                
                                <div className="d-flex align-items-center mb-3">
                                  <div className="flex-grow-1">
                                    <div className="mb-2">
                                      <h5 className="fs-12 fw-normal mb-0">{solicitud.ruc}</h5>
                                      <h6 className="fs-15 fw-bold mb-0">{solicitud.nombre_empresa}</h6>
                                    </div>
                                      <h5 className="fs-12 fw-normal mb-0">{solicitud.dni}</h5>
                                      <h6 className="fs-15 fw-bold mb-0">{solicitud.nombre_usuario}</h6>
                                  </div>
                                </div>

                                <div>
                                  <h6 className="fw-bold"> Motivo</h6>
                                  <p>
                                  {solicitud.motivo}
                                  </p>
                                </div>
                                <div>
                                  <h6 className="fw-bold"> Observación</h6>
                                  <p>
                                  {solicitud.observaciones}
                                  </p>
                                </div>
                               
                                <Row>
                                  <Col xl={6} ms={6}> 
                                      {solicitud.estado !== 'Aprobado' && (
                                        <button onClick={() => handleAprobar(solicitud.id)} className="btn btn-success w-100">Aprobar</button>
                                      )}
                                  </Col>
                                  <Col xl={6}>  
                                      {solicitud.estado !== 'Aprobado' && (
                                        <button onClick={() => handleRechazar(solicitud.id)} className="btn btn-outline-danger w-100">Rechazar</button>
                                      )}
                                  </Col>
                                 </Row>
                                
                              </CardBody>  
                            </Card>
                          </Col>
                      ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className='mb-4'>
                      <h4>Solicitudes en Proceso</h4>
                    </div>
                    <Row>
                      {filterSolicitudesByEstado('Estado.APROBADO').map(solicitud => (
                          <Col xl={4} ms={12}>
                          <Card className="card-height-100">
                            <CardBody>
                              <div className="d-flex align-items-center mb-2">
                                <div className="flex-grow-1">
                                <span>En curso</span>
                                </div>
                                <div className="flex-shrink-0">
                                  <span>{new Date(solicitud.fecha_registro).toLocaleTimeString()} </span>
								  <span>{new Date(solicitud.fecha_registro).toLocaleDateString()}</span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center mb-3">
                                <div className="avatar-sm me-3 flex-shrink-0">
                                    <div className="avatar-title bg-info-subtle rounded">
                                        <img alt="" className="avatar-xs" />
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                  <div className="mb-2">
                                    <h5 className="fs-12 fw-normal mb-0">{solicitud.ruc}</h5>
                                    <h6 className="fs-15 fw-bold mb-0">{solicitud.nombre_empresa}</h6>
                                  </div>
                                    <h5 className="fs-12 fw-normal mb-0">{solicitud.dni}</h5>
                                    <h6 className="fs-15 fw-bold mb-0">{solicitud.nombre_usuario}</h6>
                                </div>
                              </div>

                              <div>
                                <h6 className="fw-bold"> Motivo</h6>
                                <p>
                                {solicitud.motivo}
                                </p>
                              </div>
                              <div>
                                <h6 className="fw-bold"> Observación</h6>
                                <p>
                                {solicitud.observaciones}
                                </p>
                              </div>
                                <Row>
                                  <Col xl={12} ms={12}> 
                                    {solicitud.estado !== 'Finalizado' && (
                                        <button onClick={() => handleFinalizar(solicitud.id)} className="btn btn-warning w-100">Finalizar</button>
                                      )}
                                  </Col>
                                </Row>
                            </CardBody>  
                          </Card>
                          </Col>
                      ))}
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <div className='mb-4'>
                      <h4>Solicitudes Finalizadas</h4>
                    </div>
                   <Row>
                      {filterSolicitudesByEstado('Estado.FINALIZADO').map(solicitud => (
                            <Col xl={4} ms={12}>
                            <Card className="card-height-100">
                              <CardBody>
                                <div className="d-flex align-items-center mb-2">
                                  <div className="flex-grow-1">
                                  <span>Finalizado</span>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <span>{new Date(solicitud.fecha_registro).toLocaleTimeString()} </span>
									<span>{new Date(solicitud.fecha_registro).toLocaleDateString()}</span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center mb-3">
                                  <div className="avatar-sm me-3 flex-shrink-0">
                                      <div className="avatar-title bg-info-subtle rounded">
                                          <img alt="" className="avatar-xs" />
                                      </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <div className="mb-2">
                                      <h5 className="fs-12 fw-normal mb-0">{solicitud.ruc}</h5>
                                      <h6 className="fs-15 fw-bold mb-0">{solicitud.nombre_empresa}</h6>
                                    </div>
                                      <h5 className="fs-12 fw-normal mb-0">{solicitud.dni}</h5>
                                      <h6 className="fs-15 fw-bold mb-0">{solicitud.nombre_usuario}</h6>
                                  </div>
                                </div>
  
                                <div>
                                  <h6 className="fw-bold"> Motivo</h6>
                                  <p>
                                  {solicitud.motivo}
                                  </p>
                                </div>
                                <div>
                                  <h6 className="fw-bold"> Observación</h6>
                                  <p>
                                  {solicitud.observaciones_finalizacion}
                                  </p>
                                </div>
                              </CardBody>  
                            </Card>
                            </Col>
                             
                          ))}
                   </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    <div className='mb-4'>
                      <h4>Solicitudes rechazadas</h4>
                    </div>
                    <Row>
                          {filterSolicitudesByEstado('Estado.RECHAZADO').map(solicitud => (
                             <Col xl={4} ms={12}>
                             <Card className="card-height-100">
                               <CardBody>
                                 <div className="d-flex align-items-center mb-2">
                                   <div className="flex-grow-1">
                                   <span>Rechazado</span>
                                   </div>
                                   <div className="flex-shrink-0">
                                    <span>{new Date(solicitud.fecha_registro).toLocaleTimeString()} </span>
									<span>{new Date(solicitud.fecha_registro).toLocaleDateString()}</span>
                                   </div>
                                 </div>
                                 <div className="d-flex align-items-center mb-3">
                                   <div className="avatar-sm me-3 flex-shrink-0">
                                       <div className="avatar-title bg-info-subtle rounded">
                                           <img alt="" className="avatar-xs" />
                                       </div>
                                   </div>
                                   <div className="flex-grow-1">
                                     <div className="mb-2">
                                       <h5 className="fs-12 fw-normal mb-0">{solicitud.ruc}</h5>
                                       <h6 className="fs-15 fw-bold mb-0">{solicitud.nombre_empresa}</h6>
                                     </div>
                                       <h5 className="fs-12 fw-normal mb-0">{solicitud.dni}</h5>
                                       <h6 className="fs-15 fw-bold mb-0">{solicitud.nombre_usuario}</h6>
                                   </div>
                                 </div>
   
                                 <div>
                                   <h6 className="fw-bold"> Motivo</h6>
                                   <p>
                                   {solicitud.motivo}
                                   </p>
                                 </div>
                                 <div>
                                   <h6 className="fw-bold"> Rechazo</h6>
                                   <p>
                                   {solicitud.motivo_rechazo}
                                   </p>
                                 </div>
                               </CardBody>  
                             </Card>
                             </Col>
                          ))}
                     </Row>
                  </TabPane>
                </TabContent>
              </div>
          </Col>
        </Row>
      </Container>
    </section>

      {/* Vertically Centered */}
      <Modal isOpen={motivoRechazoModal} toggle={() => setMotivoRechazoModal(false)} centered>
        <ModalHeader className="modal-title" /> 
        <ModalBody className="text-center p-5">
            {/* Contenido del modal */}
            {/* Agrega un campo de entrada para el motivo de rechazo */}
            <input
                type="text"
                value={motivoRechazoState}
                onChange={(e) => setMotivoRechazoState(e.target.value)}
                className="form-control mb-3"
                placeholder="Motivo de rechazo"
            />
            <div className="hstack gap-2 justify-content-center">
                <Button color="light" onClick={() => setMotivoRechazoModal(false)}>Cancelar</Button>
                <Button color="danger" onClick={() => handleAceptarRechazo()}>Aceptar</Button>
            </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={observacionesFinalizacionModal} toggle={() => setObservacionesFinalizacionModal(false)} centered>
        <ModalHeader className="modal-title">Observaciones de Finalización</ModalHeader>
        <ModalBody className="text-center p-5">
          {/* Contenido del modal */}
          {/* Agrega un campo de entrada para las observaciones de finalización */}
          <input
            type="text"
            value={observacionesFinalizacionState}
            onChange={(e) => setObservacionesFinalizacionState(e.target.value)}
            className="form-control mb-3"
            placeholder="Observaciones de finalización"
          />
          <div className="hstack gap-2 justify-content-center">
            <Button color="light" onClick={() => setObservacionesFinalizacionModal(false)}>Cancelar</Button>
            <Button color="success" onClick={() => handleAceptarFinalizacion()}>Aceptar</Button>
          </div>
        </ModalBody>
      </Modal>
      <Footer />
  </React.Fragment>
  );
}

export default AdminLayout;